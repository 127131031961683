<template>
    <div class="prgoress_bar_wrap">
        <div class="progress_bar_width" :style="{ width: progresswidth + '%' }"></div>
    </div>
</template>
<script>
export default {
  props: {
    progresswidth: {
      type: Number,
      required: false,
      default: 0
    }
  },
  name: 'ProgressBar',

  data () {
    return {};
  },

  mounted () {},

  methods: {}
};
</script>
<style scoped>
.prgoress_bar_wrap {
  width: 100%;
  height: 6px;
  border-radius: 10px;
  background: var(--222);
  position: relative;
}
.progress_bar_width {
  background: linear-gradient(180deg, #ff2952 0%, #ff2d79 100%);
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  border-radius: 10px;
}

@media all and (max-width: 768px) {
  .prgoress_bar_wrap,
  .progress_bar_width {
    height: 2px;
  }
}
</style>
